@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS for a lighter, fading dotted pattern */
.bg-dotted-pattern {
    background-image: 
      radial-gradient(circle, rgba(128, 128, 128, 0.3) 1px, transparent 1px), /* Light gray dots */
      linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, white 100%); /* Fades to white */
    background-size: 10px 10px;
    background-repeat: repeat;
  }

  html {
    scroll-behavior: smooth;
  }

  section {
    scroll-margin-top: 80px; /* Adjust the value as needed */
  }